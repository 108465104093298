<template>
  <div>
    <YesNoDialog
        :value="value"
        title="Pool not empty"
        width="450"
        yes-text="Go to Explorers view"
        no-text="Close"
        persistent
        @input="$emit('input', $event)"
        @yes="goToExplorersView">
      You need to empty this pool first.<br/>
      Go to the Explorers view and move them to another pool.
    </YesNoDialog>
  </div>
</template>

<script>
import YesNoDialog from '@/components/extended/YesNoDialog.vue';

export default {
  name: 'DevicePoolNotEmptyDialog',
  components: {
    YesNoDialog,
  },
  props: {
    value: Boolean,
    devicePoolId: Number,
  },
  methods: {
    goToExplorersView() {
      window.location.href = `?f=administration&f2=explorers&device-pool-id=${this.devicePoolId}`;
    },
  },
};
</script>

<style scoped>

</style>